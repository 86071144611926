import React from "react"
import Header from './header'
import TestimonialHero from './testimonial-hero'
import TestimonialsGrid from './testimonial-grid'
import ScrollToTop from "react-scroll-to-top";
import Footer from './footer'

import '../styles/app.scss'

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]', {
    speed: 500,
    offset: 50
  })
}

const TestimonialsLayout = () => { 
  return (
  <div>
    <Header/>
    <ScrollToTop smooth/>
    <TestimonialHero/>
    <TestimonialsGrid/>
    <Footer/>
    </div>
  )
}


export default TestimonialsLayout
