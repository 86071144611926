import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import styled from 'styled-components'
import Logo from '../images/logo_hero.png'

import BackgroundImage from 'gatsby-background-image'

const BackgroundSection = ({ className }) => (
  <StaticQuery
    query={graphql`
    query{
        contentfulTestimonialPage{
            testimonialHero{
            fluid(quality:100, maxWidth:2840){
              ...GatsbyContentfulFluid
            }
          }
        }
       }
    `}
    render={data => {
      // Set ImageData.
      const imageData = data.contentfulTestimonialPage.testimonialHero.fluid
      return (
        <div className="hero-container">
        <BackgroundImage
          className={className}
          fluid={imageData}
          backgroundColor={`#040e18`}
        >
            <img className="logo" src={Logo} alt="" />
            <div className="hero-title">
            {/* <h2 className="text-center text-light">TESTIMONIALS</h2> */}
           
          </div>

          <div className="overlay"></div>
        </BackgroundImage>
        </div>
      )
    }}
  />
)

const TestimonialHero = styled(BackgroundSection)`
  width: auto;
  height: 85vh;
  background-position: bottom center;
  background-repeat: repeat-y;
  background-size: cover;
  border-bottom: 10px solid white;
  border-left: 10px solid white;
  border-right: 10px solid white;
  border-top: 10px solid white;
`

export default TestimonialHero