import React from "react"
import { Link } from "gatsby"

import TestimonialsLayout from "../components/testimonials-layout"



const Testimonial = () => (
<div>
  <TestimonialsLayout/>

   
</div>
)

export default Testimonial
