import { graphql, StaticQuery } from 'gatsby'
import React from 'react'
import Img from 'gatsby-image'
import '../styles/app.scss'



const TestimonialsGrid = () => (
  <StaticQuery query={graphql`
  query{
	allContentfulTestimonials(sort:{fields:sortOrder, order:ASC}){
    edges{
      node{
        testimonialText{
          testimonialText
        }
        testimonialClubLogo{
          fluid{
            ...GatsbyContentfulFluid
          }
        }
        testimonialSignature
      }
    }
  }
}
  `}

  render={data => {
    // Set ImageData.
  
    return (
    <section>
        <div className="container">
        <div className="row text-center">
            <div className="column">
            <h1 className="mb-2">TESTIMONIALS</h1>
            </div>
        </div>
            {data.allContentfulTestimonials.edges.map((item) => {
                return(
                    <div className="row text-center">
                            <p className="slider-text">{item.node.testimonialText.testimonialText} </p>
                            <div className="row ">
                            <p className="testimonial-signature">{item.node.testimonialSignature}</p>
                              <Img className="testimonial-image " fluid={item.node.testimonialClubLogo.fluid}/>
                              
                            </div>
                    </div>
                )
            })}
            
          </div>
    </section>

    )
  }
  }
 />

)

export default TestimonialsGrid;